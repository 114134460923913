<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="">
      <v-row>
        <v-col cols="12" sm="12" xs=" 12" xl="9" lg="9">
          <div class="mt-6 mb-8 text-h2 font-weight-bold">Fotos</div>
          <MultImageUpdate
            class="d-flex justify-start"
            :no-images="noImages"
            :max="20"
            upload-msg="Haz click o arrastra una imagen al recuadro (max 20 imágenes)"
            max-error="Solamente puede subir hasta 20 imágenes."
            file-error="Solamente se aceptan imágenes (.png o .jpg)"
            :pre-images="serviceImages"
            @imagesChanged="updateImages"
          />
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col cols="12" class="d-flex justify-space-between">
          <div class="text-h2 font-weight-bold">Información general</div>
        </v-col>
      </v-row>
      <v-row class="flex-column text-h4" no-gutters>
        <v-col cols="12" sm="10" md="8" lg="5" class="my-2">
          <v-row class="align-center">
            <v-col cols="auto"> Nombre del servicio: </v-col>
            <v-col>
              <v-text-field
                v-model="service.name"
                flat
                dense
                outlined
                hide-details="auto"
                single-line
                color="secondary"
                class="rounded-lg v-input--is-focused secondary--text"
                background-color="transparent"
                validate-on-blur
                maxlength="50"
                :rules="fillInput"
                @keypress.enter.prevent
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="9" md="7" lg="4" class="my-2">
          <v-row class="align-center">
            <v-col cols="auto"> Categoría: </v-col>
            <v-col>
              <v-autocomplete
                v-model="service.category"
                :items="categories"
                no-data-text="Categoría no encontrada"
                auto-select-first
                outlined
                flat
                dense
                single-line
                required
                color="secondary"
                item-text="name"
                item-color="secondary"
                background-color="transparent"
                :menu-props="{
                  bottom: true,
                  closeOnContentClick: true,
                  allowOverflow: true,
                  offsetY: true,
                  rounded: 'md',
                  transition: 'slide-y-transition',
                }"
                append-icon="fa-caret-down secondary--text"
                hide-details="auto"
                validate-on-blur
                :rules="fillInput"
                class="rounded-lg v-input--is-focused secondary--text"
                @keypress.enter.prevent
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="11" md="9" lg="6" class="my-2">
          <v-row class="align-baseline">
            <v-col cols="12" sm="auto" class="pb-0 pb-sm-auto">
              Ubicación:
            </v-col>
            <v-col cols="12" sm="">
              <v-row>
                <v-col cols="12" sm="">
                  <v-autocomplete
                    v-model="service.state"
                    :items="states"
                    no-data-text="Estado no encontrado"
                    placeholder="Estado"
                    outlined
                    flat
                    dense
                    single-line
                    required
                    color="secondary"
                    item-color="secondary"
                    background-color="transparent"
                    autocomplete="null"
                    :menu-props="{
                      bottom: true,
                      closeOnContentClick: true,
                      allowOverflow: true,
                      offsetY: true,
                      rounded: 'md',
                      transition: 'slide-y-transition',
                    }"
                    append-icon="fa-caret-down secondary--text"
                    hide-details="auto"
                    validate-on-blur
                    :rules="stateRulesSelect"
                    class="rounded-lg v-input--is-focused secondary--text"
                    @keypress.enter.prevent
                    @change="stateUpdated"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="">
                  <v-autocomplete
                    v-model="service.city"
                    :items="citySelect"
                    :no-data-text="
                      service.state
                        ? 'Ciudad no encontrada'
                        : 'Seleccione un estado'
                    "
                    hide-details="auto"
                    placeholder="Ciudad"
                    outlined
                    flat
                    dense
                    required
                    single-line
                    color="secondary"
                    item-color="secondary"
                    background-color="transparent"
                    autocomplete="null"
                    :menu-props="{
                      bottom: true,
                      closeOnContentClick: true,
                      allowOverflow: true,
                      offsetY: true,
                      rounded: 'md',
                      transition: 'slide-y-transition',
                    }"
                    append-icon="fa-caret-down secondary--text"
                    validate-on-blur
                    :rules="cityRulesSelect"
                    class="rounded-lg v-input--is-focused secondary--text"
                    @keypress.enter.prevent
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="11" md="9" lg="6" class="mt-7 my-sm-2">
          <v-row class="align-center">
            <v-col cols="auto" class="py-0">
              Cobertura en toda la república:
            </v-col>
            <v-col cols="auto" sm="" class="py-0">
              <v-radio-group
                v-model="service.allCities"
                mandatory
                row
                class="secondary--text"
              >
                <v-radio
                  :value="true"
                  :ripple="false"
                  color="secondary"
                  @keypress.enter.prevent
                >
                  <template v-slot:label>
                    <span class="black--text">Si</span>
                  </template>
                </v-radio>
                <v-radio
                  :value="false"
                  :ripple="false"
                  color="secondary"
                  @keypress.enter.prevent
                >
                  <template v-slot:label>
                    <span class="black--text">No</span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="my-2">
          <v-row class="align-center">
            <v-col cols="auto"> Precios: </v-col>
            <v-col>
              <v-rating
                v-model.number="service.priceRange"
                hover
                length="5"
                size="30"
                color="secondary"
                empty-icon="fa-dollar-sign text--lighten-4"
                full-icon="fa-dollar-sign secondary--text"
                background-color="secondary"
                class="d-inline pl-2"
                :ripple="false"
              >
              </v-rating>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="10" md="8" lg="5" class="my-2">
          <v-row class="align-baseline">
            <v-col cols="auto" class="pb-0 pb-sm-auto"> Capacidad: </v-col>
            <v-col cols="12" sm="">
              <v-row class="align-center">
                <v-col cols="auto">De</v-col>
                <v-col cols="auto" sm="">
                  <v-text-field
                    v-model="service.minPeople"
                    flat
                    dense
                    outlined
                    placeholder="0"
                    hide-details="auto"
                    type="number"
                    min="0"
                    step="1"
                    single-line
                    color="secondary"
                    class="rounded-lg v-input--is-focused secondary--text"
                    background-color="transparent"
                    validate-on-blur
                    :rules="numberInputOptional"
                    oninput="if(this.value < 0) this.value = 0;"
                    @change="
                      handleMinPeopleFormat();
                      handlePeopleFormat();
                    "
                    @keypress.enter.prevent
                  ></v-text-field>
                </v-col>
                <v-col cols="auto">a</v-col>
                <v-col cols="auto" sm="">
                  <v-text-field
                    v-model="service.maxPeople"
                    flat
                    dense
                    outlined
                    placeholder="0"
                    hide-details="auto"
                    type="number"
                    min="0"
                    step="1"
                    single-line
                    color="secondary"
                    class="rounded-lg v-input--is-focused secondary--text"
                    background-color="transparent"
                    validate-on-blur
                    :rules="numberInputOptional"
                    oninput="if(this.value < 0) this.value = 0;"
                    @change="
                      handleMaxPeopleFormat();
                      handlePeopleFormat();
                    "
                    @keypress.enter.prevent
                  ></v-text-field>
                </v-col>
                <v-col cols="auto">personas.</v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="isMaxPeopleQuantity" cols="12" class="mb-3">
          <div class="red--text">Debe contener máximo 6 cifras</div>
        </v-col>
        <v-col v-else-if="isMinGreaterThanMax" cols="12" class="mb-3">
          <div class="red--text">
            La capacidad máxima debe ser mayor o igual a la capacidad mínima.
          </div>
        </v-col>
        <v-col cols="12" sm="11" md="10" lg="7" class="my-2">
          <v-row class="align-baseline">
            <v-col cols="auto" class="pb-0 pb-sm-auto">
              Etiquetas descriptivas del servicio:
            </v-col>
            <v-col cols="12" sm="">
              <v-row class="align-center">
                <v-col>
                  <v-text-field
                    v-model="tag"
                    flat
                    dense
                    outlined
                    required
                    placeholder="Agregar etiquetas"
                    hide-details="auto"
                    single-line
                    color="secondary"
                    class="rounded-lg v-input--is-focused secondary--text"
                    background-color="transparent"
                    maxlength="20"
                    @keydown.enter.prevent="addTag"
                  ></v-text-field>
                </v-col>
                <v-col cols="auto">
                  <a
                    class="text-decoration-underline secondary--text font-weight-bold"
                    style="user-select: none"
                    @click="addTag"
                  >
                    Agregar
                  </a>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-if="!noTagList"
          cols="12"
          sm="11"
          md="10"
          lg="7"
          class="mt-2 mt-sm-0"
        >
          <Button
            v-for="(serviceTag, index) in serviceTags"
            :key="index"
            :text="serviceTag.tag"
            outlined
            pill
            active
            :height="44"
            append-icon="fa-times"
            class="mr-2 mb-2"
            aria-label="Remover etiqueta"
            @event="removeTag(index)"
          />
        </v-col>
        <v-col v-else cols="12" sm="11" md="10" lg="7">
          <div class="red--text">
            La lista de etiquetas no puede estar vacía. Favor de agregar al
            menos una etiqueta.
          </div>
        </v-col>
        <v-col cols="12" class="mt-7 my-sm-2">
          <v-row class="align-center">
            <v-col cols="auto" class="py-0">
              Colaboración con organizadores:
            </v-col>
            <v-col cols="auto" sm="" class="py-0">
              <v-radio-group v-model="service.partyPlanner" mandatory row>
                <v-radio
                  :value="true"
                  :ripple="false"
                  color="secondary"
                  @keypress.enter.prevent
                >
                  <template v-slot:label>
                    <span class="black--text">Si</span>
                  </template>
                </v-radio>
                <v-radio
                  :value="false"
                  :ripple="false"
                  color="secondary"
                  @keypress.enter.prevent
                >
                  <template v-slot:label>
                    <span class="black--text">No</span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="11" md="9" lg="7" class="my-2">
          <v-row class="align-center">
            <v-col cols="auto"> Plan de pagos: </v-col>
            <v-col>
              <v-select
                v-model="service.paymentAdvance"
                :items="advancePercentage"
                no-data-text="No hay porcentajes disponibles"
                hide-details="auto"
                outlined
                flat
                dense
                required
                single-line
                item-text="label"
                item-value="value"
                item-color="secondary"
                color="secondary"
                background-color="transparent"
                :menu-props="{
                  bottom: true,
                  closeOnContentClick: true,
                  allowOverflow: true,
                  offsetY: true,
                  rounded: 'md',
                  transition: 'slide-y-transition',
                }"
                append-icon="fa-caret-down secondary--text"
                validate-on-blur
                :rules="optionRules"
                class="rounded-lg v-input--is-focused secondary--text"
                @keypress.enter.prevent
              >
              </v-select>
            </v-col>
            <v-col>
              <v-select
                v-model="service.paymentDeadline"
                :items="settlementPeriod"
                no-data-text="No hay períodos disponibles"
                hide-details="auto"
                outlined
                flat
                dense
                single-line
                required
                item-text="label"
                item-value="value"
                item-color="secondary"
                color="secondary"
                background-color="transparent"
                :menu-props="{
                  bottom: true,
                  closeOnContentClick: true,
                  allowOverflow: true,
                  offsetY: true,
                  rounded: 'md',
                  transition: 'slide-y-transition',
                }"
                append-icon="fa-caret-down secondary--text"
                validate-on-blur
                :rules="optionRules"
                class="rounded-lg v-input--is-focused secondary--text"
                @keypress.enter.prevent
              >
              </v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="my-2 mt-4">
          <v-row class="align-center" no-gutters>
            <v-col cols="12" md="auto" class="pr-6">
              Política de cancelación:
            </v-col>
            <v-col cols="12" sm="10" md="auto">
              <v-row class="align-center">
                <v-col cols="12" md="auto">
                  <v-row class="align-center" no-gutters>
                    <v-col cols="auto">
                      <span> Permitir cancelación con </span>
                    </v-col>
                    <v-col cols="3" sm="2" md="2" class="mx-3">
                      <v-text-field
                        v-model="cancellationDeadline"
                        flat
                        dense
                        outlined
                        required
                        placeholder="00"
                        hide-details="auto"
                        single-line
                        color="secondary"
                        class="rounded-lg v-input--is-focused secondary--text"
                        background-color="transparent"
                        @keypress.enter.prevent
                        @change="handleCancellationFormat()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto">
                      <span> días previos al evento. </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="auto">
                  <v-row class="align-baseline">
                    <v-col cols="auto" class="pb-0 pb-sm-auto">
                      <span> Reembolso: </span>
                    </v-col>
                    <v-col cols="12" sm="">
                      <v-row class="align-center">
                        <v-col>
                          <v-select
                            v-model="reimbursement"
                            :items="reimbursementList"
                            no-data-text="No hay porcentajes disponibles"
                            hide-details="auto"
                            placeholder="Devolución del 0%"
                            outlined
                            flat
                            dense
                            single-line
                            required
                            item-color="secondary"
                            color="secondary"
                            background-color="transparent"
                            :menu-props="{
                              bottom: true,
                              closeOnContentClick: true,
                              allowOverflow: true,
                              offsetY: true,
                              rounded: 'md',
                              transition: 'slide-y-transition',
                            }"
                            append-icon="fa-caret-down secondary--text"
                            class="rounded-lg v-input--is-focused secondary--text"
                            @keypress.enter.prevent
                          >
                          </v-select>
                        </v-col>
                        <!-- Adapted to set only one cancellation item -->
                        <v-col cols="auto" sm="auto">
                          <a
                            class="text-decoration-underline secondary--text font-weight-bold"
                            style="user-select: none"
                            :style="
                              (cancellationList.length !== 0 ||
                                isMaxDigitsQuantity) &&
                              'pointer-events: none; cursor: default'
                            "
                            :class="
                              (cancellationList.length !== 0 ||
                                isMaxDigitsQuantity) &&
                              'text--disabled'
                            "
                            @click="addCancellationItem"
                          >
                            Agregar
                          </a>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-if="isMaxDigitsQuantity" cols="12" class="mb-3">
                  <div class="red--text">Debe contener máximo 6 cifras</div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="mt-5">
              <div>
                <div
                  v-for="cancellationItem in cancellationList"
                  :key="cancellationItem.id"
                  class="mb-1"
                >
                  <span class="mr-4">
                    Cancelación con
                    <span class="font-weight-bold">
                      {{ cancellationItem.cancellationDeadline }}
                    </span>
                    días previos al evento. Devolución del
                    <span class="font-weight-bold">
                      {{ cancellationItem.reimbursement }}%.
                    </span>
                  </span>
                  <a
                    class="text-decoration-underline secondary--text font-weight-bold"
                    @click="removeCancellationItem(cancellationItem.id)"
                  >
                    Eliminar
                  </a>
                </div>
              </div>
              <div v-if="cancellationList.length == 0" class="red--text">
                Debe agregar al menos una política de cancelación
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="mt-5 my-2">
          <div class="mb-1 text-h3 font-weight-black">Descripción</div>
          <v-textarea
            v-model="service.description"
            solo
            flat
            no-resize
            auto-grow
            outlined
            hide-details="auto"
            :rows="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 4 : 6"
            color="secondary"
            row-height="30"
            class="rounded-lg mb-6 v-input--is-focused secondary--text"
            background-color="transparent"
            maxlength="255"
            counter="255"
            :rules="descriptionRules"
          >
          </v-textarea>
        </v-col>
        <!-- <v-col class="my-2">
          <v-row>
            <v-col cols="4">
              <h4 class="mb-1">Preguntas frecuentes</h4>
              <v-text-field
                placeholder="Pregunta frecuente"
                flat
                outlined
                required
                hide-details="auto"
                single-line
                color="secondary"
                class="rounded-lg v-input--is-focused secondary--text"
                background-color="transparent"
                @keypress.enter.prevent
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                solo
                flat
                no-resize
                auto-grow
                outlined
                hide-details="auto"
                :rows="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 4 : 6"
                color="secondary"
                row-height="30"
                class="rounded-lg mb-6 v-input--is-focused secondary--text"
                background-color="transparent"
                :rules="optionalTextAreaRules"
              >
              </v-textarea>

              <a class="text-decoration-underline black--text font-weight-bold">
                +Agregar pregunta
              </a>
            </v-col>
          </v-row>
        </v-col> -->
        <v-col class="d-flex justify-end flex-wrap">
          <v-spacer></v-spacer>
          <Button
            text="Cancelar"
            outlined
            :horizontal-padding="6"
            :disabled="editLoading"
            class="mb-2"
            aria-label="Cancelar"
            @event="handleEditChange()"
          />
          <Button
            text="Guardar cambios"
            :disabled="editLoading"
            type="submit"
            class="ml-2"
            aria-label="Guardar cambios"
            @event="validate()"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import MultImageUpdate from "@/components/Shared/MultImageUpdate.vue";
import Button from "@/components/Shared/Button.vue";
import { useMutation } from "@/graphql/index";
import { UPDATE_SERVICE } from "@/graphql/mutations";
import {
  fillInput,
  stateRulesSelect,
  cityRulesSelect,
  quantityInput,
  optionRules,
  numberInputOptional,
  descriptionRules,
  optionalTextAreaRules,
} from "@/Utils/rules.js";

import { states, getCitiesByState } from "@/Utils/locations.js";
import { mapState } from "vuex";
export default {
  name: "EditServiceDetails",
  components: {
    MultImageUpdate,
    Button,
  },
  props: {
    serviceData: { type: Object, required: true },
    serviceImages: { type: Array, required: true },
    serviceId: { type: Number, required: true },
    handleEditChange: { type: Function, required: true },
    editLoading: { type: Boolean, required: true },
  },
  emits: ["handleEditLoading"],
  data() {
    return {
      valid: true,
      tag: null,
      service: null,
      serviceTags: [],
      cancellationDeadline: null,
      reimbursement: null,
      cancellationList: [],
      reimbursementList: [
        { text: "Devolucion del 0%", value: 0 },
        { text: "Devolucion del 10%", value: 10 },
        { text: "Devolucion del 20%", value: 20 },
        { text: "Devolucion del 30%", value: 30 },
        { text: "Devolucion del 40%", value: 40 },
        { text: "Devolucion del 50%", value: 50 },
        { text: "Devolucion del 60%", value: 60 },
        { text: "Devolucion del 70%", value: 70 },
        { text: "Devolucion del 80%", value: 80 },
        { text: "Devolucion del 90%", value: 90 },
        { text: "Devolucion del 100%", value: 100 },
      ],
      advancePercentage: [
        {
          label: "30% anticipo",
          value: 30,
        },
        {
          label: "50% anticipo",
          value: 50,
        },
        {
          label: "100% anticipo",
          value: 100,
        },
      ],
      settlementPeriod: [
        {
          label: "Liquidación el día del evento",
          value: "0",
        },
        {
          label: "Liquidación 3 días antes",
          value: "3",
        },
        {
          label: "Liquidación 5 días antes",
          value: "5",
        },
        {
          label: "Liquidación 7 días antes",
          value: "7",
        },
        {
          label: "Liquidación 15 días antes",
          value: "15",
        },
        {
          label: "Liquidación 30 días antes",
          value: "30",
        },
      ],
      fillInput: fillInput,
      stateRulesSelect: stateRulesSelect,
      cityRulesSelect: cityRulesSelect,
      quantityInput: quantityInput,
      optionRules: optionRules,
      numberInputOptional: numberInputOptional,
      states: states,
      descriptionRules: descriptionRules,
      optionalTextAreaRules: optionalTextAreaRules,
      noImages: false,
      noTagList: false,
      isMinGreaterThanMax: false,
      isMaxPeopleQuantity: false,
      isMaxDigitsQuantity: false,
    };
  },
  computed: mapState({
    provider(state) {
      return state.provider;
    },
    categories(state) {
      return state.categories;
    },
    citySelect() {
      return getCitiesByState(this.service.state);
    },
  }),
  created() {
    this.service = { ...this.serviceData };
    this.cancellationList.push({
      cancellationDeadline: this.service.cancellationDeadline,
      reimbursement: this.service.reimbursement,
    });
    this.service.serviceTag.forEach((tag) => {
      this.serviceTags.push({ tag: tag.tag });
    });
  },
  methods: {
    stateUpdated() {
      this.service.city = "";
    },
    removeTag(index) {
      this.serviceTags.splice(
        this.serviceTags.findIndex((tag, id) => id === index),
        1
      );
      this.noTagList = this.serviceTags.length == 0;
    },
    addTag() {
      if (this.tag !== null) {
        this.serviceTags.push({ tag: this.tag });
        this.tag = "";
        this.noTagList = this.serviceTags.length == 0;
      }
    },
    removeCancellationItem(id) {
      this.cancellationList.splice(
        this.cancellationList.findIndex(
          (cancellationItem) => cancellationItem.id === id
        ),
        1
      );
    },
    addCancellationItem() {
      if (this.cancellationDeadline !== null && this.reimbursement !== null) {
        this.cancellationList.push({
          cancellationDeadline: this.cancellationDeadline,
          reimbursement: this.reimbursement,
        });
        // Logic adapted to only set one cancellation item
        this.service.cancellationDeadline = this.cancellationDeadline;
        this.service.reimbursement = this.reimbursement;
        this.cancellationDeadline = "";
        this.reimbursement = "";
      }
    },
    async validate() {
      this.$emit("handleEditLoading", true);
      this.noImages = this.service.images.images.length == 0;
      this.noTagList = this.serviceTags.length == 0;
      if (
        this.provider &&
        this.$refs.form.validate() &&
        this.cancellationList.length > 0 &&
        !this.noImages &&
        !this.noTagList &&
        !this.isMinGreaterThanMax &&
        !this.isMaxPeopleQuantity
      ) {
        const serviceFormattedImages = this.service.images.images.map(
          (image) => ({
            base64: image.fileBase64,
            name: image.filename,
          })
        );
        const props = {
          providerId: parseInt(this.provider.id),
          allCities: this.service.allCities,
          cancellationDeadline: this.service.cancellationDeadline,
          category: this.service.category,
          state: this.service.state,
          city: this.service.city,
          description: this.service.description,
          minPeople: parseInt(this.service.minPeople || 0),
          maxPeople: parseInt(this.service.maxPeople || 0),
          name: this.service.name,
          partyPlanner: this.service.partyPlanner,
          paymentAdvance: parseInt(this.service.paymentAdvance),
          paymentDeadline: this.service.paymentDeadline,
          priceRange: parseInt(this.service.priceRange),
          reimbursement: parseInt(this.service.reimbursement),
          serviceId: this.serviceId,
          serviceImages: serviceFormattedImages,
          serviceTag: this.serviceTags,
        };

        try {
          const { errors } = await useMutation(UPDATE_SERVICE, props);

          if (errors) throw errors;

          this.handleAlert({
            type: "success",
            message: "Los cambios se han guardado correctamente.",
          });

          this.$router.go(this.$router.currentRoute);
        } catch (error) {
          console.log(error);
          this.handleAlert({
            type: "error",
            message: "Error al dar de alta datos.",
          });
        }
      }
      this.$emit("handleEditLoading", false);
    },
    updateImages(images) {
      this.noImages = images.images.length == 0;
      this.service.images = images;
    },
    handleAlert({ type, message }) {
      this.$store.dispatch("handleAlert", {
        type: type,
        message: message,
      });
    },
    handlePeopleFormat() {
      if (this.service.minPeople && this.service.maxPeople) {
        this.isMinGreaterThanMax =
          parseInt(this.service.minPeople) > parseInt(this.service.maxPeople);
      }
      this.isMaxPeopleQuantity =
        this.service.minPeople.toString().length > 6 ||
        this.service.maxPeople.toString().length > 6;
    },
    handleMinPeopleFormat() {
      this.service.minPeople = this.service.minPeople.replace(
        /^(?:0+(?=[1-9])|0+(?=0$))|,/gm,
        ""
      );
    },
    handleMaxPeopleFormat() {
      this.service.maxPeople = this.service.maxPeople.replace(
        /^(?:0+(?=[1-9])|0+(?=0$))|,/gm,
        ""
      );
    },
    handleCancellationFormat() {
      this.isMaxDigitsQuantity =
        this.cancellationDeadline.toString().length > 6;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-select__selection,
::v-deep .v-select__selection--comma,
::v-deep .v-select.v-text-field input {
  color: black !important;
}
</style>
