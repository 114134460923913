<template>
  <v-container fluid ma-0 pa-0 fill-width>
    <div class="purple-gradient fill-height">
      <Navbar />
    </div>
    <div class="body-container">
      <v-container v-if="service && !loading" class="mb-16">
        <v-container class="pb-0">
          <v-row>
            <v-col cols="12" class="d-flex row align-end">
              <Button
                text="Volver"
                pill
                outlined
                :height="43"
                prepend-icon="fa-caret-left"
                class="mt-10 mb-10"
                aria-label="Volver a lista de productos"
                url="ProviderProducts"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h1 class="text-h1">
                {{ service.name }}
              </h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-space-between">
              <v-tabs
                color="secondary"
                background-color="transparent"
                slider-size="5"
              >
                <v-tabs-slider></v-tabs-slider>
                <v-tab
                  class="btn-background-transparent text-capitalize font-weight-bold black--text text-h3 px-3 px-sm-6"
                  :ripple="false"
                  style="pointer-events: none; cursor: default"
                >
                  Información
                </v-tab>
                <v-tabs-items>
                  <v-divider color="secondary"></v-divider>
                </v-tabs-items>
              </v-tabs>
              <!-- <div class="d-flex align-end">
                <h4 class="mt-4 mr-2">Estatus:</h4>
                <v-icon class="mr-2" :color="getColor(service.status)">
                  mdi-brightness-1
                </v-icon>
                <h4 class="mt-4 mr-2">
                  {{ getText(service.status) }}
                </h4>
              </div>
              <div class="d-flex align-end flex-row mb-2">
                <h4 class="mt-4 mr-2">Estatus:</h4>
                <h4 class="mt-4 mr-2">Activado:</h4>
                <v-switch
                  v-model="service.status"
                  :value="service.status === 'active' ? false : true"
                  :color="
                    getColor(service.status) === 'active'
                      ? 'error'
                      : 'success'
                  "
                  hide-details
                ></v-switch>
                <h4 class="mt-4 mr-2">Desactivado</h4>
              </div> -->
            </v-col>
          </v-row>
          <div v-if="!isEditing">
            <v-row>
              <v-col cols="12" sm="12" xs=" 12" xl="9" lg="9">
                <div class="mt-6 mb-8 text-h2 font-weight-bold">Fotos</div>
                <div class="imgsPreview">
                  <div
                    v-for="(img, i) in service.serviceImage"
                    :key="i"
                    class="imageHolder"
                  >
                    <v-img :src="img.url" aspect-ratio="1">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-1"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row class="mb-2">
              <v-col cols="12" class="d-flex justify-space-between">
                <div class="text-h2 font-weight-bold">Información general</div>
                <Button
                  text="Editar"
                  outlined
                  :loading="!serviceImages"
                  :horizontal-padding="6"
                  aria-label="Habilitar modo de edición de servicio"
                  @event="handleEditChange(isEditing)"
                />
              </v-col>
            </v-row>
            <div class="mb-4 text-h4">
              <span class="font-weight-bold">Nombre del servicio: </span>
              <span>{{ service.name }}</span>
            </div>
            <div class="text-h4 mb-4">
              <span class="font-weight-bold">Categoría: </span>
              <span>{{ service.category }}</span>
            </div>
            <!-- <div>
            Dirección:
            <span >
              {{ service.addesss }}
            </span>
          </div> -->
            <div class="text-h4 mb-4">
              <span class="font-weight-bold">Ubicación: </span>
              <span> {{ service.city }}, {{ service.state }} </span>
            </div>
            <div class="text-h4 mb-4">
              <span class="font-weight-bold">
                Cobertura en toda la republica:
              </span>
              <span> {{ service.allCities ? "Si" : "No" }} </span>
            </div>
            <div class="text-h4 mb-4">
              <span class="font-weight-bold">Precios: </span>
              <span v-for="(p, i) in service.priceRange" :key="i">$</span>
            </div>
            <div
              v-if="service.minPeople !== 0 || service.maxPeople !== 0"
              class="text-h4 mb-4"
            >
              <span class="font-weight-bold">Capacidad: </span>
              <span v-if="service.minPeople == 0">
                Hasta {{ service.maxPeople }}
                {{ service.maxPeople > 1 ? "personas" : "persona" }}
              </span>
              <span v-else-if="service.minPeople == service.maxPeople">
                {{ service.maxPeople }} personas
              </span>
              <span v-else>
                De {{ service.minPeople }} a {{ service.maxPeople }}
                {{ service.maxPeople > 1 ? "personas" : "persona" }}
              </span>
            </div>
            <div class="text-h4 mb-4">
              <span class="font-weight-bold">
                Etiquetas descriptivas del servicio:
              </span>
              <div v-if="service.serviceTag.length > 0" class="mt-2">
                <span
                  v-for="(tag, index) in service.serviceTag"
                  :key="index"
                  class="mr-5"
                >
                  {{ index + 1 }}) {{ tag.tag }}
                </span>
              </div>
              <span v-else> Ninguna </span>
            </div>
            <div class="text-h4 mb-4">
              <span class="font-weight-bold">
                Colaboración con organizadores:
              </span>
              {{ service.partyPlanner ? "Si" : "No" }}
            </div>
            <div class="text-h4 mb-4">
              <span class="font-weight-bold">Plan de pagos: </span>
              <span>
                {{ service.paymentAdvance }}% Anticipo - Liquidación
                {{
                  service.paymentDeadline == 0
                    ? "día del evento"
                    : service.paymentDeadline + " días antes del evento"
                }}
              </span>
            </div>
            <div class="text-h4 mb-4">
              <span class="font-weight-bold">Política de cancelación: </span>
              <span>
                Cancelación con
                {{ service.cancellationDeadline }}
                días previos al evento - Devolución del
                {{ service.reimbursement }}%
              </span>
            </div>
            <div class="text-h4 mt-8 mb-4">
              <div class="font-weight-bold mb-3">Descripción</div>
              <div class="text-pre-line">
                {{
                  service.description ? service.description : "Sin descripción."
                }}
              </div>
            </div>
            <!-- <div>
            <span class="font-weight-bold mt-8 mb-4">Preguntas frecuentes</span>
            <span> ¿Pregunta frecuente? </span>
            <p class="mt-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Augue
              nisl mauris, tincidunt nulla ipsum ultricies venenatis.
            </p>
          </div> -->
          </div>
          <EditServiceDetails
            v-else
            :service-data="service"
            :service-images="serviceImages"
            :service-id="parseInt(this.$route.params.id)"
            :handle-edit-change="handleEditChange"
            :loading="loading"
            :edit-loading="editLoading"
            @handleEditLoading="handleEditLoading"
          ></EditServiceDetails>
        </v-container>
      </v-container>
      <v-container v-else>
        <InlineLoading />
      </v-container>
      <ModalLoading :dialog="editLoading" />
    </div>
    <Footer class="mt-10" />
  </v-container>
</template>

<script>
import Navbar from "@/components/Provider/Shared/Navbar.vue";
import Footer from "@/components/Shared/Footer.vue";
import InlineLoading from "@/components/Shared/InlineLoading.vue";
import Button from "@/components/Shared/Button.vue";
import EditServiceDetails from "@/components/Provider/Services/EditServiceDetails.vue";
import ModalLoading from "@/components/Shared/Modal/ModalLoading.vue";
import { GET_SERVICE_INFO, GET_SERVICE_IMAGES } from "@/graphql/queries.js";
import { useQuery } from "@/graphql/index.js";

export default {
  name: "ServicesDetails",
  components: {
    Navbar,
    Footer,
    InlineLoading,
    Button,
    EditServiceDetails,
    ModalLoading,
  },
  data() {
    return {
      isEditing: false,
      service: null,
      serviceImages: null,
      loading: false,
      editLoading: false,
    };
  },
  created() {
    this.getServices();
    this.getServiceImages();
  },
  methods: {
    // getColor(status) {
    //   if (status === "active") {
    //     return "success";
    //   } else if (status === "disable") {
    //     return "error";
    //   }
    // },
    // getText(status) {
    //   if (status === "active") {
    //     return "Activado";
    //   } else if (status === "disable") {
    //     return "Desactivado";
    //   }
    // },
    handleEditChange() {
      this.isEditing = !this.isEditing;
      this.scrollToTop();
    },
    handleEditLoading(val) {
      this.editLoading = val;
    },
    async getServices() {
      const { data, errors } = await useQuery(GET_SERVICE_INFO, {
        id: parseInt(this.$route.params.id),
      });
      if (data) {
        this.service = data.service;
      } else if (errors) {
        console.log(errors);
      }
    },
    async getServiceImages() {
      const { data, errors } = await useQuery(GET_SERVICE_IMAGES, {
        serviceId: parseInt(this.$route.params.id),
      });
      if (data) {
        this.serviceImages = data.serviceImages;
      } else if (errors) {
        console.log(errors);
      }
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
      });
    },
  },
};
</script>

<style scoped>
.purple-gradient {
  background-color: rgba(98, 37, 130, 1);
}
.btn-background-transparent::before {
  background-color: transparent !important;
}
.imgsPreview .imageHolder {
  width: 120px;
  height: 120px;
  background: #fff;
  position: relative;
  border-radius: 10px;
  margin: 5px 5px;
  display: inline-block;
}
.imgsPreview .imageHolder img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.align-div-content {
  display: flex;
  justify-content: center;
  height: 80vh;
}
</style>
